@import "Variables.scss";

.el-button--primary {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    font-family: Poppins;

    &:hover  {
    color: #fff;
    background-color: $primary-highlight;
    border-color: $primary-color;
    }
}

.primary-button {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    transition: .2s;

    &:hover  {
    color: #fff;
    background-color: $primary-highlight !important;
    border-color: $primary-color !important;
    transition: .2s;
    }
}

.no-margin {
    margin: 0 !important;
}

.el-button.is-disabled {
    background-color: #d8d8d8 !important;
    color: #5e6b7c !important;
    border: 1px solid #bfcbd9 !important;
}