/*
  Custom CZE
*/

creat-mystore-steps .el-step__head.is-text.is-process{
  background-color: $primary-color;
  border-color: $primary-color;
}

.my-store-div h2{
  color: $company-font-color;
}

.my-store-div p{
  color: $company-font-color;
  font-size: 18px;
}

.my-store-div .warning{
  color: $company-font-color;
  font-size: 15px;
}

.my-store-wizard-div h2{
  color: $company-font-color;
  margin-bottom: 0px;
}

.box-card{
  overflow: inherit !important;
}

.my-store-wizard-div .general-text{
  color: $company-font-color;
}

.my-store-wizard-div p{
  color: $company-font-color;
  font-size: 16px;
}

.my-store-wizard-div .warning{
  color: $company-font-color;
  font-size: 15px;
}

creat-mystore-steps .el-step__description.is-finish{
  color: $font-color;
  overflow-y: scroll;
}

creat-mystore-steps .el-step__head.is-text.is-success{
  background-color: $step-color;
  border-color: $step-color;
}

creat-mystore-steps .el-step__description.is-success{
  color: $step-color !important;
}

form-button .el-button--primary{
  @extend %primary-button;
}

form-button .el-button--primary:hover{
  @extend %primary-button;
  filter: brightness(110%);
}

form-button .el-button--primary:focus{
  @extend %primary-button;
  filter: brightness(110%);
}

form-button-inverse .el-button--primary{
  @extend %primary-button-inverse;
}

form-button-inverse .el-button--primary:hover{
  @extend %primary-button-inverse;
}

form-button-inverse .el-button--primary:focus{
  @extend %primary-button-inverse;
  filter: brightness(110%);
}

form-button-inverse .el-button{
  @extend %primary-button-inverse;
}

form-button-inverse .el-button:hover{
  @extend %primary-button-inverse;
  background-color: #ffebe7;
}

form-button-inverse .el-button:focus{
  @extend %primary-button-inverse;
  filter: brightness(110%);
}

form-button .el-form-item{ 
  padding-right: 30px;
}

form-button-inverse .el-form-item{ 
  padding-right: 30px;
}


form-button-ext .el-button--primary{
  @extend %primary-button;
  width: 150px !important;
}

form-button-ext .el-button--primary:hover{
  @extend %primary-button;
  width: 150px !important;
  filter: brightness(110%);
}

form-button-ext .el-form-item{ 
  padding-right: 30px;
}

.card-company{
  min-height: 450px;
}

.card-whoarewe{
  min-height: 350px;
  //max-height: 250px;
}
.description{
  white-space: pre-line;
}

.my-page-chat-btn{
  margin-bottom: 15px;
}

.card-mypage{
  border: 1px solid #d1dbe5;
  border-radius: 4px;
  background-color: #fff;
  //overflow: hidden;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
  //min-height: 280px;
}
.card-mypage h3{
  color: $company-font-color;
}
cardPageText{
  color: $company-font-color;
  font-size: 15px;
}
cardpagetextsub{
  color: $company-sub-font-color;
  font-size: 15px;
  max-height: 200px;
  overflow-y: auto;
}
.card-container {
  //position: relative;
  margin: 0px !important;
}
.company-page{
  color: $company-font-color;
}
.text-bottom-100{
  position: relative;
  bottom: -120px;
}
.company-title {
  font-size: 30px;
  backdrop-filter: brightness(0.6);
  filter: drop-shadow(2px 4px 6px black);
  position: absolute;
  top: 50px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.company-title-img-btn{
  font-size: 30px;
  position: absolute;
  top: 10px;
  left: 450px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.company-title-img-btn2{
  font-size: 30px;
  position: absolute;
  top: 100px;
  left: 120px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.company-title-img-btn .el-button--primary{
  @extend %primary-button;
  width: 160px !important;
}

.company-title-img-btn .el-button--primary:hover{
  @extend %primary-button;
  width: 160px !important;
  filter: brightness(110%);
}

.company-title-img-btn2 .el-button--primary{
  @extend %primary-button;
  width: 160px !important;
}

.company-title-img-btn2 .el-button--primary:hover{
  @extend %primary-button;
  width: 160px !important;
  filter: brightness(110%);
}

hr.solid {
  border-top: 1px solid #bbb;
  margin-top: 25px;
  margin-bottom: 25px;
}

.btn-width-200{
  min-width: 160px !important;
}

//.product-box{
  //max-width: 200px !important;
  //max-height: 100px !important;
//}

.product-box p{
  //max-width: 200px !important;
  color: $company-font-color;
  font-size: 17px;
}

.card-company-info{
  flex-flow: row wrap;
  color: $company-font-color;
  font-size: 12px !important;
}

.card-company-title{
  color: $company-sub-font-color;
  font-size: 13px !important;
}

.price-ranges{
  color: $company-sub-font-color !important;
  font-size: 18px !important;
}

.dots-pagination{
  background-color: $primary-color !important;
  opacity: 1 !important;
}
.swiper-wrapper{
  padding-left: 27px !important;
}

//.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  //left: -2px !important;
//}

.swiper-button-prev, .swiper-button-next{
  color: $primary-color !important;
}

.swiper-button-prev:hover, .swiper-button-next:hover{
  color: white !important;
  background-color: $primary-color !important;
  transition: background-color 0.3s ease;
}

.el-date-editor.el-input {
  width: inherit !important;
}

.create-store-title {
  background: red;background: rgb(249,95,109);
  background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
  padding: 20px;

  h1 {
    color: white;
    font-size: 1.6em;
    margin: 0px;
  }
  p {
    color: white !important;
    margin: 0;
  }

}

.create-store-steps {
  
}

.el-card {
  border: 1px solid white !important;
  border-radius: 16px;
}