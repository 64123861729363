@media (max-width: 900px) {
    .result-card{
        flex-direction: column !important;
        //background-color: aqua;
    }
    .center-card-img-prd{
        //margin-left: 25vw;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 50% !important;
    }
    .filter-header-layout{
        flex-direction: column !important;
    }
    
}
.special-container{
    padding-right: 16px;
}
.center-col{
    //margin-left: 25vw;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50% !important;
}
.disabled{
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    //background-color: #eef1f6;
    border-color: #d1dbe5;
}

.actions-result{
    font-size: 14px;
    font-weight: bold;
}

.link-text{
    color: #FF6D73;
    text-decoration: none;
    padding-top: 2px;

    &:hover {
        text-decoration: underline;
    }
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: $primary-color !important;
}

.result-category{
    color: $gray-medium;
    font-size: 14px;
}

.result-price{
    color: $gray-medium;
    font-size: 16px;
    font-weight: bold !important;
}

.result-min-order{
    color: $gray-medium;
    font-size: 16px;
}

.result-info span{
    //padding-left: 4px;
    padding-right: 4px;
}

.result-country{
    font-size: 16px;
    color: $gray-medium;
}

.result-verified{
    color: $gray-medium;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    -webkit-text-stroke: 0.4px white;
}

.result-bainub-account-year{
    font-size: 16px;
    color: purple;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    -webkit-text-stroke: 0.4px white;
}

.result-supplier-rating{
    color: gold;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    font-size: 14px;
    -webkit-text-stroke: 0.4px white;
}

.result-customizable{
    font-size: 16px;
    color: $gray-medium;
}

.result-by{
    color: $company-font-color;
    font-size: 16px;
}

.result-by span{
    color: $primary-color;
}

.result-company{
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
}

.result-product-name{
    color: $gray-medium;
    font-size: 18px;
}

.el-pager li.active{
    background-color: $primary-color !important;
}

.el-pagination button:hover{
    color: $primary-color !important;
}

.el-pager li:hover{
    color: $primary-color !important;
}

#old-search .el-pager li{
    cursor: not-allowed !important;
    pointer-events: none !important;
}

.filter-header{
    padding-bottom: 25px;
    //padding-top: 25px;
    margin-top: 16px;
}

.filter-header .el-card{
    padding: 0px !important;
}

.filter-header .el-card .el-card__body{
    padding: 0px !important;
}

.gray-rigth-separator{
    border-right: 1px solid #E0E0E0;
}

.filter-header-margin{
    margin-top: 20px;
    margin-bottom: 10px;
}

.filter-spacer{
    padding: 5px;
}

.el-select-dropdown__item.selected{
    color: #fff !important;
    background-color: $primary-color !important;
}

.el-slider__bar, .el-slider__button{
    background-color: $primary-color !important;
}

.left-menu-filter h2{
    color: $company-font-color;
}

.left-menu-filter h3{
    color: $company-font-color;
}
.bottom-spacer-10{
    padding-bottom: 10px;
}

.result-card-style {
    transition: .2s;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
        transition: .2s;
    }

    hr {
        border: .5px solid $gray-light;
        margin-top: 12px !important;
        margin-bottom: -6px !important;
        box-shadow: none;
    }

}

.product-banner-image {
    margin-left: 10px;
    margin-right: 10px;
    min-height: 465px !important;
}

.main-color{
    color: $primary-color;
}
