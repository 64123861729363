.table-order-products{
  //display: inline-flex;
}

.order-dots{
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 12px;
}

.inline-row{
  display: inline-flex;
}

.order-status-label{
  color: white;
  font-size: x-small;
  font-weight: 900;
  text-align: center;
  border-radius: 5px;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  display: flex;
}

.el-message-box__btns .el-button{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.el-tabs__active-bar{
  background-color: $primary-color !important;
}

.order-wizard-div{
  
}

.order-step-form{
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
  color: $form-font-color;
}

.order-form-color{
  color: $form-font-color !important;
}

.form-separator{
  border-top: 1px solid #E0E0E0;
  padding-bottom: 20px;
}

.buyer-note{
  font-size: 11px;
  color: #B1B1B1;
}

.el-table__body-wrapper{
  min-height: 180px !important;
}

.order-total-label{
  text-align: right;
  color: $font-color;
  font-weight: 600;
}

.order-notes-label{
  color: $font-color;
  font-weight: 600;
}

.order-total-amount{
  text-align: left;
}

.order-notes-text{
  //overflow: scroll;
  overflow-wrap: break-word;

}

.history-font{
  color: $font-color;
  font-size: medium;
  font-weight: 700;
}

.shipping-label{
  color: $font-color !important;
  font-weight: 600;
}

.order-card{
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0 0px 0 rgb(0 0 0 / 0%) !important;
}

.banner-btn{
  border-radius: 27px !important;
  background-color: white !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
  font-weight: bold !important;
}

.banner-chat-btn{
  @extend .banner-btn;
  width: 180px !important;
}

.banner-cancel-btn{
  @extend .banner-btn;
  width: 180px !important;
}

.card-order-warning .el-card__body{
  padding: 20px 0 0 0 !important;
}

.reject-order-btn{
  border-radius: 27px !important;
  background-color: #F74F4F !important;
  border-color: #F74F4F !important;
  font-weight: bold;
  width: 140px;
}

.approve-order-btn{
  border-radius: 27px !important;
  background-color: #8FE376 !important;
  border-color: #8FE376 !important;
  font-weight: bold;
  width: 140px;
}

.history-warning{
  color: #48576a;
  font-size: 10px;
}