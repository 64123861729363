@import "Variables.scss";

.other-color{
    background-color: $otherColor !important;
}

.card-dash-total{
    color: $greenNew;
    font-size: 30px;
    font-weight: 700;
    //padding-bottom: 20px !important;
}

.card-dash-icon{
    margin-top: 7px !important;
}

.card-dash-link{
    margin-top: 20px;
    text-decoration: underline;
    font-size: 12px;
}

.card-dash-link-premium{
    font-size: 11px;
    font-weight: bold;
}

.card-dash-note{
    margin-top: 12px;
}

.box-card-dash{
    min-height: 140px !important;
}

.box-card-dash .el-card__body{
    padding: 10px !important;
}

.box-card-dash .el-col-24{
    //padding: 1px !important;
    //margin: 1px !important;
    max-height: 40px !important;
}

.centerCard {
    display: flex;
    align-items: end;
}

.box-card-dash-premium{
    background-color: #e3fefe !important;
}

.dash-test-list{
    margin: 5px;
}

.clean-text{
    color: $font-title-primary-color;
    font-size: 12px;
}

.CookieConsent {
    color: $form-font-color !important;
}

.notifications-header {
    background: rgb(249,95,109);
    padding: 0px 2px 2px 12px;
    background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
    color: white !important;
    height: 40px;
    margin-bottom: 1em;
    border-radius: 6px;
  
    h3 {
      color: white !important;
      font-size: 22px;
      font-weight: 500;
      margin-left: 16px;
      margin: 6px;
    }
  }

.NotificationsIcon {
    width: 30px;
    margin: 4px;
}

.mr-3 {
    margin-right: 30px;
}

.PdfIcon {
    width: 40px;
    margin: 0em 1em 1em 0em;
    vertical-align: middle;
}

.hr-dashboard {
    border: 1px solid $gray-light-hr;
}

.dashboard-bg {
    background-color: $gray-light-hr;
}

.dashboard-card-box {
    background: white;
}

.h1-1 {
    font-size: 3em;
    color: $primary-color;
    font-weight: 300;
    margin-top: 1em;
}
.h1-2 {
    font-size: 3em;
    color: $gray-medium;
    font-weight: 300;
    margin-top: 1em;
}

.el-alert--info {
    background-color: #fef0f0 !important;
    color: $gray-medium !important
}

.el-alert {
    width: 100%;
    padding: 12px !important; 
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    color: $gray-medium !important;
    opacity: 1;
    display: table;
    transition: opacity .2s;
}

.el-alert .el-alert__description {
    color: $gray-medium !important;
    font-size: .9em !important;
}

.el-button--primary {
    color: #fff;
    background-color: #F76566 !important;
    border-color: #F76566 !important;
    font-weight: bold;
    min-width: 140px;
    border-radius: 27px !important;
}

.el-alert__icon {
    color: #F76566 !important;
    vertical-align: top !important;
}

.el-alert__title {
    color: #F76566 !important;
}

.el-button--secondary {
    color: #F76566 !important;
    background-color: #fff !important;
    border-color: #F76566 !important;
    font-weight: bold;
    min-width: 140px;
    border-radius: 27px !important;
}