$font-title-primary-color: #707070;
$font-form-label-primary-color: #393E46;

.form-title {
  color: $font-title-primary-color;
  font-size: 1.7rem;
  padding-top: 5px;
  font-weight: 600;
  display: flex;
  align-items: left;
  justify-content: left;
}

.form-subtitle {
  color: $font-title-primary-color;
  font-size: 1.2rem;
  font-weight: 600;
  // text-transform: uppercase;
  display: flex;
  align-items: left;
  justify-content: left;
}

.form-input-label {
  color: $font-form-label-primary-color;
  font-family: "Poppins";
  font-size: .9rem;
  font-weight: 600;
  display: flex;
  align-items: left;
  justify-content: left;
}

.first-title-row {
  margin-bottom: 15px;
}

.title-row {
  margin-bottom: 15px;
  margin-top: 15px;
}

.isolated-row {
  margin-bottom: 20px;
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

.red-dash {
  color: #ff4949;
}

.el-input-group__prepend {
  border: 1px solid #bfcbd9;
}

.secondary-button {
  margin-left: 20px;
  margin-right: 20px;
  color: $primary-color;
  background-color: white;
}

.secondary-button.is-disabled {
  background-color: #eef1f6 !important;
}

.white-anchor {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-decoration: underline;
  font-family: "Poppins";
}

.white-primary-button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: white !important;
  border: 1px solid $primary-color !important;
  border-radius: 20px !important;
  font-family: "Poppins";
  font-weight: bold;
  color: $primary-color !important;
}

.white-primary-button.is-disabled {
  background-color: #eef1f6 !important;
}

.primary-button {
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: $primary-color !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  line-height: normal !important;
  font-family: "Poppins";

}

.primary-button.is-disabled {
  background-color: #eef1f6 !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
  justify-content: center;
}

.login-card {
  padding: 0px !important;
}

.login-card-content {
  color: white !important;
  margin: 5rem 3rem 1rem 3rem !important;
}

.login-card-title {
  margin-top: .6em;
  font-size: 1.7rem !important;
  font-weight: 600;
  display: block;
  color: #1d3449;
}

.login-card-title-2 {
  font-size: 1.4rem !important;
  font-weight: 600;
  display: block;
  color: #1d3449;
}

.login-card-item {
  font-size: 1rem !important;
  display: block;
  margin: 1rem 0rem 0rem 0rem;
  color: #848484;
}

.login-card-note {
  font-size: .7rem !important;
  display: block;
  // margin: 2rem 0rem 0rem 0rem;
}

.login-card-image-col {
  background-size: cover !important;
  background-position: center;
  padding: 1em !important;
  margin: 0px !important;



}

@media (max-width: 1600px) {
  .login-card-image-col {
    background-size: cover !important;
    background-repeat: no-repeat;
    //background-color: #393E46;
  }
}

@media (max-width: 758px) {
  .hidden-mobile {
    display: none;
  }
}

.login-card-button-col {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.welcome-banner-card-content {
  /*margin: 4rem 4rem 1rem 7rem !important; */
  padding-left: .6em;
  padding-right: .6em;

  h1 {
    color: white;
    font-size: 2.8em;
    font-weight: 300;
    line-height: 1.1;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
  }

  a {
    color: white;
  }

  h2 {
    color: white;
    font-weight: 500;
    margin-top: 0px;
    font-size: 1.4em;
  }

  img {
    width: 100%;

  }
  span {
    color: $primary-color;
  }

  p {
    color: white;
    margin-bottom: 26px;
    margin-top: 0px;
    font-size: .9em;
  }

  .logo {
    width: 200px;
    margin-top: 36px;
  }

}

.welcome-banner-card-title {
  font-size: 1.4rem !important;
  display: block;
  color: #393E46;
  font-weight: 600;
}

.welcome-banner-card-item {
  font-size: 1rem !important;
  display: block;
  margin: 1rem 0rem 0rem 0rem;
  color: #393E46;
}

.welcome-banner-card-button-col {
  display: flex;
  justify-content: left;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.el-menu--horizontal .el-submenu .el-submenu__title {
  //line-height: 20px !important;
  //margin-top: 10px;
}

#correo {
  color: $primary-color;
  font-size: 11px;
  margin-left: 35px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.form-description {
  font-size: .8em;
}

.AboutUsSwiper {
  color: $gray-blue;
  background: #f6f6f6 !important;
  
  h3 {
  color: $primary-color;
  font-weight: 600;
  font-size: 1.6em;
  }

  h4 {
    color: $gray-blue;
    font-weight: 400;
    }

  .swiper-wrapper
  {
    height: 36vh !important;
  }
}

.swiper-pagination-bullet-active {
  background: $primary-color !important;
}

.welcome-banner-card-text {
  font-size: .8em;
}

.welcome-banner-card-text-privacy {
  font-size: .8em;
  color: #909090;
}

.inline-form {
  display: inline-block;
  width: 48%;
}

.mr-1 {
  margin-right: .6em;
}

.ml-2 {
  margin-left: 1.2em;
}

.mr-2 {
  margin-right: 1.2em;
}