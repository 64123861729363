
$step-color: #bfcbd9;
$company-font-color: #606060;
$company-sub-font-color: #393E46;
$font-title-primary-color: #606060;

$primary-color: #F76566;
$primary-highlight: #e45253;
$secondary-color: #18ACBA;
$font-color: #989898;
$gray-light-hr: #eeeeee;
$gray-light: #E1E1E1;
$gray-medium: #4A4A4A;
$gray-blue: #182237;
$form-font-color: #393E46;
$spacer: 1;

$primary-color-fosfo: #F76566;

$otherColor: #f86254;

$greenNew: #18ACBA;
