@import "Variables.scss";

$leftMenuColor : #FFFFFF;

@media screen and (max-width: 756px) {
    .sidenav {
      display: none !important;
    } 
  }
  .leftBack{
    background-color: $company-sub-font-color;
    min-height: 100vh;    
  }
  span .el-menu-item, .el-submenu__title{
    line-height: normal !important;
  }

  li .el-submenu .el-menu-item{
    line-height: normal !important;
  }
  li .el-menu-item, .el-submenu__title{
    line-height: normal !important;
  }
  .menu2-title{
    position: absolute !important;
    top: 25% !important;
  }
  .menu1-title{
    white-space : break-spaces !important;
    padding-right: 26px;
  }
  .filtered-left-menu{
    background-color: #FFFFFF !important;
  }
  .main-left-menu{
      background-color: #393E46 !important;
      color:#FFFFFF !important;
      min-height: 100vh;
  }

  .sidenav{
   /* box-shadow: 1px 1px 3px rgb(0 0 0 / 10%); */
  }

  .main-left-menu .sidenav{

  }

  .main-left-menu .sidenav .el-menu{
    background-color: #393E46;
    color:#FFFFFF !important;
  }
  .main-left-menu .sidenav .el-submenu__title{
    color:#FFFFFF;

    &:hover {
      background-color:#535965;
      color: $primary-color;
    }

  }

  .el-menu-item {
    &:hover {
      color: $primary-color !important;
    }
  }

.menu-categories {
  &:hover {
    color: $primary-color !important;
  }
}

  .el-submenu__title:focus-within {
    background-color:#535965 !important;
    color: $primary-color;

  }

  .main-left-menu .sidenav .el-menu-item{
    /* background-color: #393E46 !important; */
    color:#FFFFFF !important;
  }

.sidenav {
    display: block;
    //position: absolute;
    z-index: 1;
    //box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
    //min-width: 200px !important;
    min-height: 100vh;
}

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #2196F3;
    display: block;
}

.sidenav a:hover {
    color: #064579;
}

.sidenav .el-menu-vertical-demo{
    min-height: calc(100vh - 150px);
   /* background-color: $leftMenuColor !important; */
}

.sidenav .el-menu-item-group__title{
    background-color: $leftMenuColor !important;
}

.sidenav .el-menu-item{
   /* background-color: $leftMenuColor !important; */
}

.menu-selected{
    color: $primary-color !important;
}


.el-submenu .el-menu-item {
    padding: 6px 30px !important;
    
    &:hover {
      color: $primary-color !important;
    }
}

.el-submenu .el-menu {
background: none;
}

.el-submenu .el-menu-item:hover, .el-submenu__title:hover {
  background-color: transparent !important;
}

.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item:hover, .el-menu--horizontal.el-menu--dark .el-submenu .el-submenu-title:hover, .el-menu-item:hover {
  background-color: transparent !important;
}

.el-submenu .el-menu-item {
  padding: 6px 0px 12px 40px !important;
  height: auto !important;
}

.main-left-menu {
  transition: top 0.1s ease-in-out;
}

.seller-menu {
  .el-menu-item, .el-submenu__title {
    height: 40px;
  }
}

@media (min-width: 1200px) {
  .seller-menu {
      width: 16% !important;
    }
}