.photo-card{
  border-radius: 6px;
  border: 1px solid white;
}
.photo-card:hover{
  border-color: $primary-color !important;
}

display-product-info{
  color: $company-font-color !important;
  line-height: 0px !important;
}

.link-company-name{
  color: $primary-color !important;
  cursor: pointer;
}
.product-info-values{
  color: $company-sub-font-color !important;
}

.max-img-prewiew{
  //max-height: 442px !important;
  min-height: 442px !important;
}

.pp-separator{
  max-height: 10px !important;
}

.pp-info-button{
  width: 200px !important;
}

.pp-line-height{
  line-height: 0px !important;
}
.primary-color{
  color: $primary-color !important;
}

.el-tabs__item.is-active{
  color: $primary-color !important;
}

.special-container {
	//border: 3px solid blue;
	max-width: 80vw;
	&__image {
		//width: 400px;
    max-width: 50vw;
		margin: 0px 30px 30px 0;
		float: left;
	}
	&__text {
		display: inline;
    color: $company-font-color !important;
	}
}

.bottom-separator{
  padding-bottom: 10px !important;
}
.top-separator{
  padding-top: 10px !important;
}

.rigth-separator{
  padding-right: 10px !important;
}
.left-separator{
  margin-left: 10px !important;
}

.input-width-200{
  max-width: 200px !important;
}

.title-text{
  font-size: 20px;
}

.input-customA{
  width : 80px !important;
}

.el-dropdown-menu__item{
  width: 95px !important;
}

.responsivePadding {
  padding-left: 4em !important;
  padding-right: 4em !important;
}

@media only screen and (max-width: 600px) {

  .responsivePadding {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

}