/*
  Custom CZE
*/

.search-input-content {
    padding: 12px;
  }
  .header-color{
    background-color: #FFFFFF !important;
  }
  
  .general-color{
    background-color: #222831 !important;
  }
  .select-category{
    width: 12.5em;
    min-width: 140px;
  }
  
  .footer {
    clear: both;
    position: relative;
    background-color: #dcdcdc !important;
    max-width: none !important;
    font-size: 12px;
    padding: 12px;
  }

  .footer a {
    color: #747474 !important;
  }
  
  .all-but-footer{
    min-height: calc(100vh - 150px);
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 600px) {
    .all-but-footer{
      min-height: calc(100vh - 150px);
      margin-left: 0px;
    }

    .top_stripe_banner {
      display: none;
    }

  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    .all-but-footer{
      min-height: calc(100vh - 150px);
      //background-color: #FCFCFC;
      margin-left: 100px;
    }
    .top_stripe_banner {
      display: none;
    }
  }

  #main-search-input{
    border-radius: 0px 27px 27px 0px !important;
    box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
    border-color: white;
    background-color: white;
    border-left-style: solid !important;
    border-left-color: #C1CCD7;
  }
  
  
  .el-input-group__prepend{
    border-radius: 27px 0px 0px 27px !important;
    box-shadow:-3px 4px 8px 2px rgb(0 0 0 / 10%) !important;
    border-color: white !important;
    background-color: white !important;
  }
  
  #main-search-button .el-button--primary{
    border-radius: 27px;
    background-color: $primary-color;
    border-color: transparent;
    font-weight: bold;
    box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
    width: 138px;
    transition: .2s;
    color: white !important;

    &:hover {
      background-color: $primary-highlight;
      transition: .2s;
    }


  }
  
  #main-login-button .el-button--primary{
    border: 0px;
    border-color: $primary-color;
    background-color: #ffebe7;
    color: white;
    //border-color: transparent;
    font-weight: bold;
    //box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
    width: 138px;
    position: relative;
    top: -5px;
    transition: .2s;

    &:hover {
      background: #fbd9d3;
      transition: .2s;
    }

  }
  
  #main-login-button .el-icon-login{
    font-family: icomoon!important;
  }
  
  #main-login-button .el-icon-login:before {
    content: "\e900";
  }
  
  textarea:focus, input:focus {
    color: #888888;
  }
  @media (min-width: 990px) {
    .submenuBar{
      justify-content: center;
      display: flex;

    }
  }


  #sub-menu-without-session i{
    display: none;
  }

  #sub-menu-without-session .force-show{
    display: inline-block; 
  }
  
  #sub-menu-without-session .el-submenu__title{
    color: #888888;
    font-weight: bold;
    //background-color: #FFFFFF !important;
  }

  #sub-menu-without-session .el-submenu__title:hover{
    background-color: transparent !important;
  }
  
  #header-bottom-div{
    //border-style: solid;
    border-width: thin;
    border-color: #E0E0E0;
    border-top-style: solid;
    border-bottom-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
    z-index: 10 !important;
    box-shadow: 3px 3px 7px rgb(0 0 0 / 10%);
    position: relative;
  }
  
  #sub-menu-without-session .el-submenu{
    top: -5px;
  }

  #sub-menu-without-session .el-menu{
    color: #888888;
    font-weight: bold;
    background-color: #FFFFFF !important;
    
  }
  #sub-menu-without-session .el-menu-item{
    color: #888888;
    font-weight: bold;
    background-color: #FFFFFF !important;
  }
  
  #sub-menu-without-session .el-menu-item:hover {
    background-color: $primary-color !important;
    color: #FFFFFF !important;
  }
  #sub-menu-single-without-session .el-menu-item:hover {
    background-color: #FFFFFF !important;
  }
  .header-submenu{
    color: #888888;
    font-weight: bold;
    background-color: #FFFFFF !important;
  }
  
  .flex-column {
    max-height: 60px !important;
  }
  .flex-column div{
    flex-direction: column !important;
    align-content: stretch;
    align-items: flex-start;
    max-height: 22px !important;
  }
  
  .align-items-center {
        align-items: center;
  }
  
  .header-icon-text{
    color: $primary-color;
    font-size: 10px;
    font-weight: bold;
  }
  
  .top-menu{
    //margin-top: 10px !important;
    box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
  }

  .top-menu .el-row{
    top: 2px;
  }

  .top-banner-header{
    background-color: #13083d;
    padding: 4px;
    margin-top: -3px;
    font-size: .8em;
    color: white;

    a {
      color: white !important;
      margin-left: 6px;
      margin-right: 6px;
    }

  }

  .align_right {
    text-align: right;
  }

  .t-m-1 {
    margin-left: .5em;
    margin-right: .5em;
  }

  .top_stripe_banner {
    background-color: #2d3647;
    color: white;
    margin-bottom: .8em;
    padding: .5em .5em .3em .5em;
    font-size: .9em;
  }

  #header-bottom-div .el-row{
    top: 0px !important;
  }

  #lngDiv {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  #lngDiv .el-input__inner{
    max-width: 100px;
  }

  /*.search-input-content .el-input__inner{
    max-width: 150px !important;
    min-width: 150px !important;
  }*/

  .label-mini{
    font-size: 12px;
    padding-right: 4px;
    color: #888888;
    font-weight: bold;
    
  }
  .label-mini-border{
    border-right: 1px solid #E0E0E0;
  }

  .flag {
    width: 25px;
    height: 15px;
    cursor: pointer;
    position: relative;
    top: 3px;
  }

  .dialog-body-footer{
    max-height: 60vh !important;
    overflow: auto !important;
  }
