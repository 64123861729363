.AboutUsPage {
    padding-top: 2em;

    h1 {
        color: #14123f;
        font-weight: 600;
        font-size: 1.7em;
    }
    h2 {
        color:#14123f;
        font-weight: 600;   
    }
    h3 {
        font-size: 1.5em;
        font-weight: 600;
        color: white;
    }

    img {
        max-width: 60%;
        text-align: center;
    }

    .img-center {
        text-align: center;
    }

    .section01 {
        padding: 2em;

    }

    .section02 {
        background-color:#f5f5f5;
        padding: 2em;
        margin-bottom: 2em;

    }

    .section03 {
        margin-top: 8em;
        background-color:#14123f;
        padding-top: 8em;
        padding-bottom: 2em;
        text-align: center !important;

        img {
            width: 160px;
        }
    }

    .TalktoUs {
        z-index: 1000;
        width: 60%;
        padding: 2em;
        border-radius: 20px;
        background: rgb(249,95,109);
        background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
        margin: 0 auto;

        h2 {
            color: white;
        }
        p { color: white;}

        .button {
            padding: .6em 1em .6em 1em;
            background-color: white;
            border-radius: 25px;
            color: #14123f;
            text-align: center;
            max-width: 190px;
            margin: 0 auto;

        }

        .responsiveSpacer {
            height: 2.5em;
        }


@media (max-width: 985px) {

    .responsiveSpacer {
      height: .1em;
    }
  }

    }

    .TalkContainer {
        width: 100%;
        position: absolute;
        z-index: 100;
        margin-top: 2.1em;
          
    }
}

.img-center {
    text-align: center;
}

@media (max-width: 985px) {

    .responsiveSpacer2 {
      height: 3em;
    }
  }

  @media (max-width: 600px) {

    .hidde-mobile {
     display: none;
    }

    .AboutUsPage {
        padding-top: 0px;
    }

  }


  @media (min-width: 601px) {

    .hidde-desktop {
     display: none;
    }
  }