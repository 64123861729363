
%primary-button {
  border-radius: 27px;
  background-color: $primary-color;
  border-color: $primary-color;
  font-weight: bold;
  //box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
  min-width: 140px;
  color: white !important;
}

%primary-button-inverse{
  border-radius: 27px;
  background-color: transparent;
  border-color: $primary-color;
  font-weight: bold;
  //box-shadow: 3px 5px 13px rgb(0 0 0 / 10%);
  min-width: 140px;
  transition: .2s;
}

%primary-button-on-warning{
  border-radius: 27px;
  background-color: transparent;
  border-color: white;
  color: white;
  font-weight: bold;
  min-width: 140px;
}

@for $i from 1 through 10 {
  $width-space: 100px * $i;
  .inline-flex-#{$width-space} { 
    display: inline-flex;
    width: $width-space;
  }
}

.el-form-item__label {
  text-align: left !important;
}

.tooltip-question {
  background-color: $gray-light;
  font-size: 16px; 
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid $gray-light;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;

}

.EditSolidIcon {
  margin-top: 35px;
}

.pt-1 {
  padding-top: 1em;
}

.m-t-4 {
  margin-top: 4em;
}

.circle {
  position: absolute;
  margin-top: .5em;

  /* top: 19%; */
  /* left: 44%; */
  transform: translate(-300%,-130%);
  /* width: 20px; */
  /* height: 20px; */
  background-color: $primary-color;
  color: white;
  text-align: center;
  line-height: 100px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius: 50%;
  font-size: 1.3rem;
  &:hover{
      cursor: pointer;
  }
  &::after,&::before{
      content: "";
      display: block;
      position: absolute;
      top:0;
      left:0;
      width: 20px;
      height: 20px;
      background: rgba(255, 95, 122,1);
      border-radius: 50%;
      z-index: -1;
      animation: grow 1s ease-in-out infinite;
  }
  &::after{
      background: rgba(255, 95, 122,0.4);
      &::before{
      content: "";
      display: block;
      position: absolute;
      top:0;
      left:0;
      width: 100px;
      height: 100px;
      background: rgb(95, 132, 255);
      border-radius: 50%;
      z-index: -1;
      animation: grow 1s ease-in-out infinite;
      }
  }
  &::before{
      background: rgba(255, 95, 122,.6);
      animation-delay: -0.5s;
  }
}
@keyframes grow{
  0%{
      transform: scale(1,1);
      opacity: 1;
  }
  100%{
      transform: scale(1.8,1.8);
      opacity: 0;
  }
}

.no-gutter {
  margin: 0px !important;
  padding: 0px !important;
}

.pb-3 {
  padding-bottom: 3em;
}

.pl-1 {
  padding-left: 1em !important;
}

.about-us-swiper-text {
  padding-left: 6em;
  padding-right: 6em;
}

.secondary-form-mp {
  background-color: $gray-blue;
  padding: 1em;
  text-align: center !important;
  justify-content: center !important; 

  h1 {
    color: white;
    text-transform: uppercase;
    font-size: 1.4em;
  }
  h3 {
    color: white;
    font-size: 1em;
    font-weight: 500;
  }
}

.circle-text {
  display: inline-block;
}

.w20 {
  width: 20%;
}

.registration_custom {
  .header01 {
    margin-top: -30px !important;
    border-right: 1px solid rgb(228, 228, 228);
  }

  @media (max-width: 550px) {
    .pl-1 {
      padding: 0px;
    }
  }

}

.email_label_register {
  background-color: rgb(229, 240, 252);
  border-radius: 6px;
  padding: 10px !important;
  height: 36px;
  min-width: 100%;

}

.top-searches-padding {
  padding: 1em .5em 1em .5em;
}

.top-searches-box {
  border-radius: 2em;
  border: 1px solid gray;
  padding: .5em;
  font-size: .8em;
  margin-top: -4px;
  text-align: center;

}

.RFQ-bg {
  background-image: url();
}

.rfq-section {

  background-repeat: no-repeat;
  background-size: cover;

  padding: 1.8em 2em 0em 2em;

    h1 {
      color: white;
      font-size: 2.2em;
      margin-bottom: 1em;
  }
    
  h2 {
    color: white;
    font-size: 1.8em;
    font-weight: 500;
    
  }

  h3 {
    color: white;
    font-size: 1em;
    font-weight: 500;

  }

  p {
    color: white;
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }

  .primary-button {
    text-decoration: none !important;
    font-size: 1.2em;
    color: white !important;
  }

}


.rfq-section-1 {

  background: #182237;
  min-height: 400px;
  text-align: center;

  padding: 2em;

    h2 {
      color: white;
  }

  h3 {
    color: white;
    font-weight: 500;
}
    
  p {
    color: white;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 16px;
  }

}

.rfq-section-2 {

  background: #182237;
  min-height: 400px;

  padding: 2em;

    h2 {
      color: white;
  }

  h3 {
    color: white;
    font-weight: 500;
}
    
  p {
    color: white;
  }

}

.RfqLaptop {
  width: 100%;
}

.rfq-tag {
  text-align: center;
  color: white;
  padding: .5em 1em .5em 1em;
  display: inline-block;
  font-size: .9em;
}

.rfq-tag2 {
  border: 1px solid white;
  border-radius: 25px;
  text-align: center;
  color: white;
  padding: .5em 1em .5em 1em;
  display: inline-block;
  margin: .2em;
  font-size: .9em;
}

.db-stepbox-container-done {
  background:white;
  padding: 12px;
  border-radius: 6px;
  width: 90%;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1);
  margin-bottom: 26px;
  transition: 1s;
  border: 1px solid white;

  & {
    background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
    transition: 1s;
    cursor: pointer;

    .check-circle {
      background: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      float: right;
      margin-top: -40px;
    }
    
    .check {
      color: #4CAF50;
      font-size: 24px;
      font-weight: bold;
    }

    .header-box {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    .box-title {
      color:white;
      font-size: 22px;
      font-weight: bold;
      display: inline-block;
    }

    .icon-box {
      display: inline-block;
      color: white;
      width: 30px;
      height: 30px;
      float: right;
      margin-top: -8px;
      font-size: 18px;
      font-weight: 800;
      border: 2px solid white;
      border-radius: 50%;
      text-align: center;
      padding: 4px;
      background: transparent;
    }

    }

  .header-box {
    color:  rgba(249,95,109,1);
    font-size: 14px;
    font-weight: bold;
  }

  .box-title {
    font-size: 22px;
    display: inline-block;
  }

  .icon-box {
    display: inline-block;
    color: white;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: 800;
    border: 2px solid white;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
  }

}


.db-stepbox-container {
  background:white;
  padding: 12px;
  border-radius: 6px;
  width: 90%;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1);
  margin-bottom: 26px;
  transition: 1s;
  border: 1px solid salmon;

  &:hover {
    background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
    transition: 1s;
    cursor: pointer;

    .header-box {
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    .box-title {
      color:white;
      font-size: 22px;
      font-weight: bold;
      display: inline-block;
    }

    .icon-box {
      display: inline-block;
      color: white;
      width: 30px;
      height: 30px;
      float: right;
      margin-top: -8px;
      font-size: 18px;
      font-weight: 800;
      border: 2px solid white;
      border-radius: 50%;
      text-align: center;
      padding: 4px;
      background: transparent;
    }

    }

  .header-box {
    color:  rgba(249,95,109,1);
    font-size: 14px;
    font-weight: bold;
  }

  .box-title {
    color:#272727;
    font-size: 22px;
    display: inline-block;
  }

  .icon-box {
    display: inline-block;
    color: white;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: 800;
    border: 2px solid white;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    background: linear-gradient(340deg, rgba(249,95,109,1) 0%, rgba(27,20,100,1) 100%);
  }

  /*
  .icon-box {
    display: inline-block;
    color: white;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: 800;
    border: 2px solid white;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    background:rgba(249,95,109,1);
    background-image: url('../assets/imgs/check.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
  } */
/*
  .icon-box-checked {
    display: inline-block;
    color: white;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -8px;
    font-size: 18px;
    font-weight: 800;
    border: 2px solid white;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    background:rgba(249,95,109,1);
    background-image: url('../assets/imgs/check.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 

  } */

}

.text-right {
  text-align: right !important;
}

.el-collapse-item__header {
  font-weight: 600;
}

.m-span {
  margin-left: 1em;
  margin-right: 1em;
}


.PlayIcon {
  width: 20px;
  position: absolute;
  cursor: pointer;
}

.app-container {
  min-height: 100vh;
}

.left-space-order {
  padding-left: 1em;
}

.order-card {
  margin: 1em;
}

.align-right {
  text-align: right;
}

.button-elipsis {
  width: 50px !important;
}

.hide {
  display: none !important;
}

