.m-none { margin: 0 !important; }
.p-none { padding: 0 !important; }
.m-t-none { margin-top: 0 !important; }
.p-t-none { padding-top: 0 !important; }
.m-r-none { margin-right: 0 !important; }
.p-r-none { padding-right: 0 !important; }
.m-b-none { margin-bottom: 0 !important; }
.p-b-none { padding-bottom: 0 !important; }
.m-l-none { margin-left: 0 !important; }
.p-l-none { padding-left: 0 !important; }
.m-xxs { margin: 0.125rem !important; }
.p-xxs { padding: 0.125rem !important; }
.m-t-xxs { margin-top: 0.125rem !important; }
.p-t-xxs { padding-top: 0.125rem !important; }
.m-r-xxs { margin-right: 0.125rem !important; }
.p-r-xxs { padding-right: 0.125rem !important; }
.m-b-xxs { margin-bottom: 0.125rem !important; }
.p-b-xxs { padding-bottom: 0.125rem !important; }
.m-l-xxs { margin-left: 0.125rem !important; }
.p-l-xxs { padding-left: 0.125rem !important; }
.m-xs { margin: 0.25rem !important; }
.p-xs { padding: 0.25rem !important; }
.m-t-xs { margin-top: 0.25rem !important; }
.p-t-xs { padding-top: 0.25rem !important; }
.m-r-xs { margin-right: 0.25rem !important; }
.p-r-xs { padding-right: 0.25rem !important; }
.m-b-xs { margin-bottom: 0.25rem !important; }
.p-b-xs { padding-bottom: 0.25rem !important; }
.m-l-xs { margin-left: 0.25rem !important; }
.p-l-xs { padding-left: 0.25rem !important; }
.m-sm { margin: 0.5rem !important; }
.p-sm { padding: 0.5rem !important; }
.m-t-sm { margin-top: 0.5rem !important; }
.p-t-sm { padding-top: 0.5rem !important; }
.m-r-sm { margin-right: 0.5rem !important; }
.p-r-sm { padding-right: 0.5rem !important; }
.m-b-sm { margin-bottom: 0.5rem !important; }
.p-b-sm { padding-bottom: 0.5rem !important; }
.m-l-sm { margin-left: 0.5rem !important; }
.p-l-sm { padding-left: 0.5rem !important; }
.m-md { margin: 1rem !important; }
.p-md { padding: 1rem !important; }
.m-t-md { margin-top: 1rem !important; }
.p-t-md { padding-top: 1rem !important; }
.m-r-md { margin-right: 1rem !important; }
.p-r-md { padding-right: 1rem !important; }
.m-b-md { margin-bottom: 1rem !important; }
.p-b-md { padding-bottom: 1rem !important; }
.m-l-md { margin-left: 1rem !important; }
.p-l-md { padding-left: 1rem !important; }
.m-lg { margin: 2rem !important; }
.p-lg { padding: 2rem !important; }
.m-t-lg { margin-top: 2rem !important; }
.p-t-lg { padding-top: 2rem !important; }
.m-r-lg { margin-right: 2rem !important; }
.p-r-lg { padding-right: 2rem !important; }
.m-b-lg { margin-bottom: 2rem !important; }
.p-b-lg { padding-bottom: 2rem !important; }
.m-l-lg { margin-left: 2rem !important; }
.p-l-lg { padding-left: 2rem !important; }
.m-xl { margin: 4rem !important; }
.p-xl { padding: 4rem !important; }
.m-t-xl { margin-top: 4rem !important; }
.p-t-xl { padding-top: 4rem !important; }
.m-r-xl { margin-right: 4rem !important; }
.p-r-xl { padding-right: 4rem !important; }
.m-b-xl { margin-bottom: 4rem !important; }
.p-b-xl { padding-bottom: 4rem !important; }
.m-l-xl { margin-left: 4rem !important; }
.p-l-xl { padding-left: 4rem !important; }
.m-xxl { margin: 8rem !important; }
.p-xxl { padding: 8rem !important; }
.m-t-xxl { margin-top: 8rem !important; }
.p-t-xxl { padding-top: 8rem !important; }
.m-r-xxl { margin-right: 8rem !important; }
.p-r-xxl { padding-right: 8rem !important; }
.m-b-xxl { margin-bottom: 8rem !important; }
.p-b-xxl { padding-bottom: 8rem !important; }
.m-l-xxl { margin-left: 8rem !important; }
.p-l-xxl { padding-left: 8rem !important; }