
@import "./scss/_Variables.scss";
@import "./scss/_BaseComponents.scss";

//@font-face {
  //font-family: 'Poppins';
  //src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
//}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: bold;
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'icomoon';
  src: local('icomoon'), url(./assets/fonts/icomoon.ttf) format('truetype');
}

html,
body {
  font-family: "Poppins", "icomoon", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  background-color: #FCFCFC;
  overflow-x: hidden;
  font-weight: 400;
  //max-width: 1080;
}

h2 {
  margin-block-start: 0.53em;
  margin-block-end: 0.53em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 700;
}

body {
  margin: auto;
  max-width: 1800px !important;
  background-color: #FCFCFC;
}

@media (max-width: 550px) {
  .app-container {
    padding-right: 0em;
    padding-left: 0px;
  }
}

@media (max-width: 900px) {
  .header-rows {
    flex-direction: column;
  }

  .header01 {
    border-right: 0px !important;
  }

}

@media (max-width: 400px) {
  .app-container {
    padding: 1em 0.5em;
  }
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-container {
  max-width: 300px;
  margin: 0.3em;
}

.card-body {
  padding: 0.5em 1em;
}

.card-box-shadow-none {
  box-shadow: none !important;
}

.flex {
  display: flex;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 7px !important;
}

.mx-1 {
  margin: 0 0.5em;
}

.my-2 {
  margin: 1em 0;
}

.ml-2 {
  margin-left: 1em;
}

.mb-1 {
  margin-bottom: 7px;
}

.mb-mr {
  margin: 0 5px 5px 0;
}

.items-center {
  display: flex;
  align-items: center;
}

.pt-1 {
  padding-top: .5em;
}

.pt-2 {
  padding-top: 1em;
}

.pt-3 {
  padding-top: 1.5em;
}

.pt-4 {
  padding-top: 2em;
}

.pt-5 {
  padding-top: 2.5em;
}

.pb-1 {
  padding-bottom: .5em;
}

.pb-2 {
  padding-bottom: 1em;
}

.pb-3 {
  padding-bottom: 1.5em;
}

.pb-4 {
  padding-bottom: 2em;
}

.pb-5 {
  padding-bottom: 2.5em;
}

.mt-0 {
  margin-top: 0em!important;
}

.mt-1 {
  margin-top: .5em;
}

.mt-2 {
  margin-top: 1em;
}

.mt-3 {
  margin-top: 1.5em;
}

.mt-4 {
  margin-top: 2em;
}

.mt-5 {
  margin-top: 2.5em;
}

.mb-0 {
  margin-bottom: 0em!important;
}

.mb-1 {
  margin-bottom: .5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.mb-3 {
  margin-bottom: 1.5em !important;
}

.mb-4 {
  margin-bottom: 2em !important;
}

.mb-5 {
  margin-bottom: 2.5em !important;
} 

.app-title {
  font-weight: 300;
  font-size: 1.5rem;
  color: #f90;
  margin: -3px;
}

.app-icon {
  height: 30px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.app-user {
  font-weight: 300;
  font-size: 1rem;
}

.text-center {
  text-align: center;
  margin: 0;
}

.text-right {
  text-align: right;
  margin: 0;
}

.text-green {
  color: green;
}

.message {
  z-index: 2000 !important;
}

.error {
  color: red;
}

.progress {
  margin: 1em;
}

.dialog {
  max-width: 500px;
}

/* center items in navbar if screen width < 520px */
@media (max-width: 520px) {
  .nav-container {
    justify-content: center;
  }
}

/* push items in navbar to opposite ends if screen width > 520px */
@media (min-width: 520px) {
  .nav-container {
    justify-content: space-between;
  }
}

/* remove the navbar item that contains the Greetings text if width > 672px */
@media (max-width: 690px) {
  .nav-items .el-menu-item:nth-child(1) {
    display: none !important;
  }
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
}

.nav-link {
  text-decoration: none;
}

.profile-tabs > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-profile > * {
  background: rgba(255, 0, 0, 0.2);
}

.link {
  text-decoration: none;
  font-weight: bold;
  margin-right: 5px;
  color: var(--lightSquidInk);
}

.link:hover {
  text-decoration: underline;
}

.header {
  color: var(--color-purple);
  font-size: 1.2rem;
  padding: 5px 10px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-label {
  color: #747474;
  font-weight: bold;
  font-size: 20px;
}

.market-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.market-title {
  font-weight: 300;
  border-radius: 10px;
  text-decoration: #f90 solid underline;
  text-underline-position: under;
  text-decoration-style: wavy;
  font-size: 2.5rem;
  display: flex;
  color: var(--lightSquidInk);
  align-items: center;
}

.market-title-button {
  padding-left: 0.5em !important;
  color: #f90 !important;
  font-size: 30px !important;
}

@media (min-width: 640px) {
  .market-title {
    font-size: 3rem;
  }
}

@media (max-width: 540px) {
  .market-title {
    font-size: 2.1rem;
  }
  .market-title-button {
    font-size: 25px !important;
  }
}

@media (max-width: 500px) {
  .market-title {
    font-size: 1.8rem;
  }
  .market-title-button {
    font-size: 25px !important;
  }
}

@media (max-width: 420px) {
  .market-title {
    font-size: 1.6rem;
  }
  .market-title-button {
    font-size: 20px !important;
  }
}

@media (max-width: 350px) {
  .market-title {
    font-size: 1.5rem;
  }
  .market-title-button {
    font-size: 15px !important;
  }
}

.image-preview {
  height: 200px;
  width: 400px;
  object-fit: scale-down;
  //border-radius: 50%;
  padding-top: 10px;
  padding-left: 100px;
}

.icon {
  margin-right: 5px;
}

.large-icon {
  height: 30px;
  margin-right: 2px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: row dense;
  justify-items: center;
  grid-gap: 0.2em;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.cursor{
  cursor: pointer;
}

.primary-color {
  color: $primary-color !important;
}

.title-primary-color {
  color: $font-title-primary-color !important;
}

.el-switch__core {
  width: 80px !important;

  span {
    left: 12px !important;
  }

  .el-switch__button {
  left: -1px !important; 
  }
}

.el-switch__label {
  left: 14px !important;

  }

  .el-switch.is-checked .el-switch__core {
  width: 86px !important;
  }

  .is-checked .el-switch__button {
    left: 29px !important;
  }

  .el-switch__core {
    background: #5783b6 !important;
    border: 1px solid #5083bf !important;
  }

@media (max-width: 470px) {
  .hide-xs {
    display: none;
  }
}

.el-switch.is-checked .el-switch__core{
  border-color: $primary-color !important;
    background-color: $primary-color !important;
}

.white-text{
  color: white;
}

.dialog-width-1000 {
  min-width: 1000px !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.selected{
  color: $primary-color !important;
}

h3{
  color: $company-font-color;
}

h4{
  color: $company-font-color;
}

h5{
  color: $company-font-color;
}

.main-logo{
  color: $primary-color !important;
  background-color: transparent !important;
  z-index: 1000;
}

.main-logo-text{
  font-size: 26px !important;
  color: #32363d;
  font-weight: bold;
}

.suppliersRow {
  background: #f6f6f6 !important;

  .el-card {
    border: none;
    background-color: transparent !important;
  }

  .el-card__body {
    padding: 16px !important;
  }

  .swiper-slide {
    background: white;
  }

}

.productsRow {

  .product-swiper-item {
    border: none !important;
  }
  .el-card {
    border: none;
    background-color: transparent !important;
  }

  .el-card__body {
    padding: 16px !important;
  }
}

.surveyRow {
  background: #ecebeb;

  .el-card {
    border: none;
    background-color: transparent !important;
  }

  .el-card__body {
    padding: 16px !important;
  }

  .swiper-slide {
    background: white;
  }

}

.swiper-container-pointer-events {
  border-left: 1px solid #e1e8ee;
  border-right: 1px solid #e1e8ee;
}

.el-card{
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 12%), 0 0 0px 0 rgb(0 0 0 / 4%) !important;
}

.swiper-pagination-bullet-active{
  color: $primary-color !important;
}

.swiper-wrapper {
  padding: 8px;
  height: 360px !important;
}

.swiper-item {
  display: flex;
  justify-content: center;
}

.swiper-button-prev {
  left: 0px !important;
}

.swiper-button-next {
  right: 0px !important;
}

.supplier-swiper-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  min-height: 300px;
  border: 1px solid #d1dbe5 !important;
  border-radius: 4px !important;
  overflow: hidden;
  width: 100% !important;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  background: white;
  border-radius: 50%;
  width: 30px !important;
  border: 1px solid #FF6D73 !important;
  padding: 7px;
  height: 30px !important;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 22px !important;
}

.supplier-swiper-item img {
  max-width: 100% !important;
}

.supplier-swiper-item p {
  color: $company-font-color;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}

.product-swiper-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  min-height: 320px;
  border: 1px solid #d1dbe5 !important;
  border-radius: 4px !important;
  overflow: hidden;
  width: 100% !important;
  height: 300px;
  transition: .2s;
}

.product-swiper-item:hover {
  height: 100%;
  z-index: 100;
  transition: .2s;

}

.product-swiper-item img {
  max-width: 100% !important;
  min-height: 200px;
}

.product-swiper-item-productname {
  color: $company-font-color;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.product-swiper-item-company {
  color: $company-font-color;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.product-swiper-item-price {
  color: $company-font-color;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}

.left-menu-title {
  font-weight: bold;
  color: #48576a !important;
}

.left-menu-item {
  color: #48576a !important;
}

.display-none {
  display: none !important;
}

.el-message-box__content{
  white-space: pre-line;
}

.privacy-policy-title {
  margin-bottom: 1rem;
}

.privacy-policy-content {
  margin-bottom: 1rem;
}

.el-input-group {
  border: 1px solid #ededed;
  border-radius: 50px;
}

.float-right {
  float: right;
}

#category-title {
  font-size: 11px;
  font-weight: bold;
  color: #48576a;
}

#product-category {
  font-size: 10px;
  font-weight: normal;
}

.minAlto{
  min-height: 800px !important
}

.center-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 880px) {
  .el-collapse-item__header{
    font-size: 10px !important;
    //color: black !important;
  }
}

.el-collapse-item.is-active>.el-collapse-item__header .el-collapse-item__header__arrow{
  color: $primary-color !important;
}

.el-collapse-item.is-active>.el-collapse-item__header{
  color: $primary-color !important;
}

.image-half {
  img {
    width: 50%;
  }
}

@media (min-width: 880px) {
  .el-collapse-item__header{
    font-size: 1.1rem !important;
    //color: black !important;
    padding: 0.5rem !important;
  }
  
  .el-collapse-item__content{
    font-size: 1rem !important;
    padding: 0.5rem !important;
    color: #48576a !important;
    margin: 10px !important;
  }
}

.privacy-padding {
  padding: 2.5em;
}

.custom-file-upload {
  display: inline-block;
  padding: 0px 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: $primary-color; /* Color de fondo */
  color: white; /* Color del texto */
  border: 2px solid $primary-color; /* Color del borde */
  border-radius: 5px; /* Bordes redondeados */
  font-weight: bold;
}



.custom-file-upload input[type="file"] {
  display: none;
}


@import "./scss/_Header.scss";
@import "./scss/LeftMenu.scss";
@import "./scss/_NonMenuForm.scss";
@import "./scss/_CreateCompany.scss";
@import "./spacing.scss";
@import "./scss/_CreateProduct.scss";
@import "./scss/_MyProductTable.scss";
@import "./scss/_ProductView.scss";
@import "./scss/_SearchResults.scss";
@import "./scss/_Avatar.scss";
@import "./scss/_Chat.scss";
@import "./scss/_Orders.scss";
@import "./scss/_Dashboard.scss";
@import "./scss/_Buttons.scss";
@import "./scss/_AboutUs.scss";