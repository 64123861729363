.avatar-sm {
    height: 2.25rem !important;
    width: 2.25rem !important;
}

.avatar-md {
    height: 3rem !important;
    width: 3rem !important;
}

.avatar-title {
    align-items: center !important;
    color: #FF6D73 !important;
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;
    background-color: white !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 50% !important;
    font-size: x-large;
    font-weight: bold;
}