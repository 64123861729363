
.product-wizard-div h2{
  color: $company-font-color;
}

.left-space{
  padding-left: 20px;
}

.italic{
  font-style: italic;
}

.cascader-add-product{
  width: 400px;
}

.el-tag{
  margin-right: 10px;
}

.hyphen-center{
  padding-top: 40px;
}

.product-category-sub-title{
  color: #838383;
  font-size: 12px;
  margin-left: 25px;
}

.create-company-step0 .el-radio__input.is-checked .el-radio__inner{
  border-color: $primary-color !important;
  background: white !important;
}

.create-company-step0 .el-radio__inner::after{
    width: 12px !important;
    height: 12px !important;
    background-color: $primary-color !important;
}

.radio-custom .el-radio__input.is-checked .el-radio__inner{
  border-color: $primary-color !important;
  background: white !important;
}

.radio-custom .el-radio__inner::after{
    width: 12px !important;
    height: 12px !important;
    background-color: $primary-color !important;
}

.radio-text{
  display: inherit;
  color: $company-sub-font-color !important;
}

.el-cascader-menu__item.is-active{
  background-color: $primary-color-fosfo !important;
  color: $company-sub-font-color !important;
}

.el-tag--primary {
  background-color: $primary-color-fosfo !important;
  //border-color: $primary-color-fosfo !important;
  color: $company-sub-font-color !important;
}

.route-sub-title{
  color:$company-font-color;
  font-size: 16px;
}

.detail-sub-title{
  color:$company-font-color;
  font-size: 14px;
  font-weight: normal !important;
}

.warning-card{
  background-color: $primary-color !important;
}
.warning-card .el-card__body{
  padding: 0px !important;
}

.reduce-top-space{
  top: -20px;
  position: relative; 
}

form-button-warning .el-button--primary{
  @extend %primary-button-on-warning;
}

form-button-warning .el-button--primary:hover{
  @extend %primary-button-on-warning;
  filter: brightness(120%);
}

form-button-warning .el-button--primary:focus{
  @extend %primary-button-on-warning;
  filter: brightness(120%);
}

form-button-warning .el-button{
  @extend %primary-button-on-warning;
}

form-button-warning .el-button:hover{
  @extend %primary-button-on-warning;
  filter: brightness(120%);
}

form-button-warning .el-button:focus{
  @extend %primary-button-on-warning;
  filter: brightness(120%);
}

sub-product-items .el-form-item__label{
  text-align: left !important;
  min-width: 180px !important;
}

sub-product-items {
  width: 50%;
}

.label-custom{
  font-size: 14px;
  color: #48576a;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  max-width: 800px;
  transform: translateX(33%);
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  //display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.delete {
  background-color: red;
  width: 15px;
  padding: 5px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 10;
}

.thumb-preview{
  padding: 10px;
}

.product-wizard-div .el-dialog__footer{
  position: absolute;
  bottom: 0;
  right: 0;
}

.photos-div .el-button--primary{
  margin: 10px;
  background-color: transparent;
  border-color: $primary-color !important;
}

.photos-div .el-button--primary:hover{
  margin: 10px;
  background-color: transparent;
  border-color: $primary-color !important;
}

.jodit-dialog__content table{
  border-collapse: collapse;
  border: 1px solid black;
}

.jodit-dialog__content tbody{
  border-collapse: collapse;
  border: 1px solid black;
}

.jodit-dialog__content tr{
  border-collapse: collapse;
  border: 1px solid black;
}

.jodit-dialog__content td{
  border-collapse: collapse;
  border: 1px solid black;
}
