.my-product-table .el-table__row{
  color: $company-font-color;
}

.my-product-table h2{
  color: $company-font-color;
}

/*.my-product-table th .cell{
  color: $company-font-color;
}*/

.my-product-table .el-table .cell, .el-table__footer-wrapper, .el-table__header-wrapper{
  overflow: inherit !important;
}

.my-product-table .el-select-dropdown__item.selected{
  background-color: transparent !important;
}

.my-product-table .el-select-dropdown__item.selected:hover{
  background-color: transparent !important;
}

.products-table{
  min-height: 220px;
}