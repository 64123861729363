.chat-items-container {
    height: 620px;
    overflow-y: scroll;
}

.active-chat-item {
    background-color: #f3f3f3 !important;
}

.chat-item-title {
    color: #48576a;
    font-weight: bold;
    font-size: 14px;
    // display: block;
}

.chat-item-alias {
    color: #bfcbd9 !important;
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    // display: block;
}

.chat-item-last-message {
    color: #707070;
    font-size: 13px;
    display: block;
}

.chat-item-date {
    color: #707070;
    font-size: 11px;
    position:absolute;                 
    bottom: 0;                         
    right: 0;       
}

.chat-item-unread-flag {
    background-color: red;
    font-size: 11px;
    position:absolute;                 
    top: 0;                         
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.chat-messages {
    border: 1px solid #bfcbd9;
    //height: 325px;
    height: 50vh;
    padding: 10px;
    overflow-y: scroll;
}

.chat-messages-title {
    border: 1px solid #bfcbd9;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}

.chat-messages-title-label-sm {
    color: #707070;
    font-size: 11px;
    display: block;
}

.chat-messages-inputs {
    border: 1px solid #bfcbd9;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
}

.chat-messages-input-attachment-selected {
    color: #06ABB4;
}

.chat-input-button {
    font-size: 11px !important;
    font-weight: bold;
    margin-top: 2px !important;
    background-color: $primary-color !important;
}

.chat-item-category {
    color: #707070;
    font-size: 12px;
    font-weight: bold;
    display: block;
}

.chat-item-description {
    color: #707070;
    font-style: italic;
    font-size: 13px;
    display: flex;
    align-items: left;
    height: 100%;
}

.chat-item-description > svg {
    display: block;
    margin: 0 auto;
    text-align: left;
}

.chat-product-referece-message-title {
    font-style: italic;
    font-size: 14px !important;
    color: #bfcbd9;
}

.chat-product-referece-message-product-detail > label {
    color: #707070;
    font-size: 14px;
    text-decoration: underline;
}

.chat-product-referece-message-product-name {
    font-weight: bold;
    display: block;
    font-size: 20px !important;
    text-decoration: none !important;
}

.chat-product-referece-message-product-message {
    border-top: 1px solid #bfcbd9;
    padding-top: 10px;
    color: #707070;
    font-size: 14px;
}

.other-message {
    color: #707070;
    background-color: #E5E5E5;
}

.own-message {
    color: #06ABB4;
    background-color: #DDFDFF;
    margin-left: auto; 
    margin-right: 0;

}

.chat-attachment-message {
    max-width: 350px;
    min-width: 100px;
    border-radius: 5px;
    font-size: 14px;
    display: table;
}

.chat-attachment-message-body {
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    align-items: center;
}


.chat-text-message {
    max-width: 350px;
    min-width: 100px;
    border-radius: 5px;
    font-size: 13px;
    display: table;
}

.chat-text-message-body {
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    align-items: center;
}

.message-datetime {
    color: #707070;
    font-size: 10px;
}

.chat-non-chat-selected {
    color: #A2A2A2;
    font-weight: bold;
    text-align: center;
    width: 899px;
    display: table-cell;
    padding-top: 180px;
}

.chat-empty-inbox-leftside label {
    color: #A2A2A2;
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
    display: block;
}

.chat-empty-inbox-rightside {
    border-left: 3px solid #A2A2A2;
}

.chat-empty-inbox-rightside label{
    font-size: 13px;
    display: block;
}

.seller-chat {
    border: 1px solid #06ABB4 !important;
}